import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export async function fetchStationDrcConnect(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/connect`, params, {headers});
}

export function fetchStationDrcEnter(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/enter`, params, {headers});
}

export function fetchStationDrcExit(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/exit`, params, {headers});
}
