import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";


export function fetchStationMissionPause(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/mission/pause`, params, {headers});
}

export function fetchStationMissionResume(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/mission/resume`, params, {headers});
}

export function fetchStationMissionStop(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/mission/stop`, params, {headers});
}
