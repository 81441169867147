
import store from "@/store";
import { fetchStationDrcConnect } from '@/api/station/stationDrc.js'
import {UranusMqtt} from '@/station/mqtt/index'

// const StatusOptions = {
//   status: 'close',
//   event: CloseEvent,
// } | {
//   status: 'open',
//   retryCount : null,
// } | {
//   status: 'pending',.0
// }

class UseConnectMqtt {
  mqttState = null

  async setDeviceDockInfo(val, callBackUseConnectMqtt) {
    // 1.스테이션 연결
    // 2.장치에는 비행 제어 권한이 있습니다
    // 3.mqtt 연결 설정을 위한 인증 정보 요청
    if (val) {
      if (this.mqttState != null) return
      console.log("UseConnectMqtt setDeviceDockInfo",val)
      let result = await fetchStationDrcConnect({cls : "dji"})
      if (result.status === 200 && result.data.result === 0) {
        const data = result.data.data;
        const username = data.username;
        const password = data.password;

        const mqttOption = {
          clientId: data.clientId,
          username,
          password,
        }

        console.log("UseConnectMqtt fetchStationDrcConnect result", data.address, mqttOption)
        this.mqttState = new UranusMqtt(data.address, mqttOption);
        console.log("UseConnectMqtt fetchStationDrcConnect", this.mqttState == null ? "null" : this.mqttState)
        if(this.mqttState != null) {
          this.mqttState.initMqtt()
          this.mqttState.on('onStatus', (statusOptions) => {
            console.log("UseConnectMqtt fetchStationDrcConnect statusOptions", statusOptions)
            // @TODO: 异常case
          })
        }
        store.dispatch("station/SET_MQTT_STATE", this.mqttState)
        store.dispatch("station/SET_CLIENT_ID", data.clientId)
        if(callBackUseConnectMqtt !== undefined) {
          callBackUseConnectMqtt(true)
        }
      } else {
        console.error("UseConnectMqtt fetchStationDrcConnect error", result)
        if(callBackUseConnectMqtt !== undefined) {
          callBackUseConnectMqtt(false)
        }
      }
      return
    }

    if (this.mqttState) {
      console.log("UseConnectMqtt setDeviceDockInfo destroyed")
      this.mqttState.destroyed()
      this.mqttState = null
      store.dispatch("station/SET_MQTT_STATE", null)
      store.dispatch("station/SET_CLIENT_ID", null)
    }
  }

  onUnmounted = (() => {
    if(this.mqttState !== null) {
      this.mqttState.destroyed()
      this.mqttState = null
    }
  })
}

export default UseConnectMqtt;
