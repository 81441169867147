import {DRC_METHOD,} from '@/station/type/drc'
import UseMqtt from './use-mqtt.js'
let myInterval = null;

export const ControlKeyCode= Object.freeze({
  "KEY_R" : 'KeyR', //드론 up
  "KEY_F" : 'KeyF', //드론 down
  "KEY_Q" : 'KeyQ', //드론 좌 회전
  "KEY_E" : 'KeyE', //드론 우 회전
  "KEY_W" : 'KeyW', //드론 전진
  "KEY_A" : 'KeyA', //드론 왼쪽
  "KEY_S" : 'KeyS', //드론 후진
  "KEY_D" : 'KeyD', //드론 오른쪽
});

export class UseManualControl {

  useManualControlState = null
  deviceTopicInfo = null
  mqttHooks = null
  seq = 0
  activeCodeKey = null

  constructor(_useManualControlState, _deviceTopicInfo) {
    this.useManualControlState = _useManualControlState;
    this.deviceTopicInfo = _deviceTopicInfo;
    this.mqttHooks = new UseMqtt()
    this.mqttHooks.setStoreMqttState(this.deviceTopicInfo)
  }

  handlePublish(params) {
    const body = {
      method: DRC_METHOD.DRONE_CONTROL,
      data: params,
    }

    this.handleClearInterval()
    myInterval = setInterval(() => {
      body.data.seq = this.seq++
      this.seq++
      // window.console.log('ControlKeyCode>>>>', this.activeCodeKey, this.deviceTopicInfo.pubTopic)
      this.mqttHooks?.publishMqtt(this.deviceTopicInfo.pubTopic, body, { qos: 0 })
    }, 50)
  }

  handleKeyup (keyCode) {
    if (!this.deviceTopicInfo.pubTopic) {
      console.error("DRC 링크가 설정되었는지 확인하세요.")
      return
    }
    const SPEED = 5 //  check
    const HEIGHT = 5 //  check
    const W_SPEED = 20 // 속도

    this.seq = 0
    switch (keyCode) {
      case 'KeyA':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ y: -SPEED })
        this.activeCodeKey = keyCode
        break
      case 'KeyW':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ x: SPEED })
        this.activeCodeKey = keyCode
        break
      case 'KeyS':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ x: -SPEED })
        this.activeCodeKey = keyCode
        break
      case 'KeyD':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ y: SPEED })
        this.activeCodeKey = keyCode
        break
      case 'KeyR':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ h: HEIGHT })
        this.activeCodeKey = keyCode
        break
      case 'KeyF':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ h: -HEIGHT })
        this.activeCodeKey = keyCode
        break
      case 'KeyQ':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ w: -W_SPEED })
        this.activeCodeKey = keyCode
        break
      case 'KeyE':
        if (this.activeCodeKey === keyCode) return
        this.handlePublish({ w: W_SPEED })
        this.activeCodeKey = keyCode
        break
      default:
        break
    }
  }

  handleClearInterval () {
    clearInterval(myInterval)
    // console.log('handleClearInterval>>>>', myInterval)
    myInterval = null
  }

  resetControlState () {
    this.activeCodeKey = null
    this.seq = 0
    this.handleClearInterval()
  }

  onKeyup () {
    this.resetControlState()
  }

  onKeydown (e) {
    this.handleKeyup(e.code)
  }

  handleEmergencyStop () {
    if (!this.deviceTopicInfo.pubTopic) {
      console.error("DRC 링크가 설정되었는지 확인하세요.")
      return
    }
    const body = {
      method: DRC_METHOD.DRONE_EMERGENCY_STOP,
      data: {}
    }
    this.resetControlState()
    window.console.log('handleEmergencyStop>>>>', this.deviceTopicInfo.pubTopic, body)
    this.mqttHooks?.publishMqtt(this.deviceTopicInfo.pubTopic, body, { qos: 1 })
  }

  onUnmounted = (() => {

  })
}
