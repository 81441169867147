import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function fetchStationLiveStreamCapacityList(params) {
    return axios.get(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/live/stream/capacity`, {headers, params: params});
}

export function fetchStationLiveStreamStart(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/live/stream/start`, params, {headers});
}

export function fetchStationLiveStreamStop(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/live/stream/stop`, params, {headers});
}


export function fetchStationLiveStreamUpdate(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/live/stream/update`, params, {headers});
}

export function fetchStationLiveStreamSwitch(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/live/stream/switch`, params, {headers});
}
