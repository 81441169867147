import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function fetchStationDeviceDockDebug(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/debug`, params, {headers});
}

export function fetchStationDeviceDockJobFlayToPointStart(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/jobs/fly-to-point`, params, {headers});
}

export function fetchStationDeviceDockJobFlayToPointStop(params) {
    return axios.delete(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/jobs/fly-to-point`,{headers, params: params});
}

export function fetchStationDeviceDockJobTakeoffToPoint(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/jobs/takeoff-to-point`, params, {headers});
}

export function fetchStationDeviceDockAuthorityFlight(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/authority/flight`, params, {headers});
}

export function fetchStationDeviceDockAuthorityPayload(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/authority/payload`, params, {headers});
}

export function fetchStationDeviceDockPayloadCommands(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/dock/payload/commands`, params, {headers});
}



//region data and class payload

export class PostPayloadAuthBody {
    constructor(_payload_index) {
        this.payload_index = _payload_index
    }
}

// TODO: 화면 드래그 제어
export const PayloadCommandsEnum = Object.freeze({
    "CameraModeSwitch" : 'camera_mode_switch',
    "CameraPhotoTake" : 'camera_photo_take',
    "CameraRecordingStart" : 'camera_recording_start',
    "CameraRecordingStop" : 'camera_recording_stop',
    "CameraFocalLengthSet" : 'camera_focal_length_set',
    "CameraFocusModeSet" : 'camera_focus_mode_set',
    "GimbalReset" : 'gimbal_reset',
    "CameraAim" : 'camera_aim',
    "CameraScreenSplit" : 'camera_screen_split',
    "CameraScreenDrag" : 'camera_screen_drag'
});

export class PostCameraModeBody {
    constructor(_payload_index, _camera_mode) {
        this.payload_index = _payload_index

        //CameraMode
        this.camera_mode = _camera_mode
    }
}

export class PostCameraPhotoBody {
    constructor(_payload_index) {
        this.payload_index = _payload_index
    }
}

export class PostCameraRecordingBody {
    constructor(_payload_index) {
        this.payload_index = _payload_index
    }
}

export class DeleteCameraRecordingParams {
    constructor(_payload_index) {
        this.payload_index = _payload_index
    }
}

export class PostCameraFocalLengthBody {
    constructor(_payload_index, _camera_type, _zoom_factor) {
        this.payload_index = _payload_index
        //CameraType
        this.camera_type = _camera_type
        this.zoom_factor = _zoom_factor
    }
}

export class PostGimbalResetBody{
    constructor(_payload_index, _reset_mode) {
        this.payload_index = _payload_index
        //GimbalResetMode
        this.reset_mode = _reset_mode
    }
}

export class PostCameraAimBody{
    constructor(_payload_index, _camera_type, _locked, _x, _y) {
        this.payload_index = _payload_index
        //CameraType
        this.camera_type = _camera_type
        this.locked = _locked
        this.x = _x
        this.y = _y
    }
}

export const PostPayloadCommandsBody = {
    cmd: PayloadCommandsEnum.CameraModeSwitch,
    data: PostCameraModeBody
} | {
    cmd: PayloadCommandsEnum.CameraPhotoTake,
    data: PostCameraPhotoBody
} | {
    cmd: PayloadCommandsEnum.CameraRecordingStart,
    data: PostCameraRecordingBody
} | {
    cmd: PayloadCommandsEnum.CameraRecordingStop,
    data: DeleteCameraRecordingParams
} | {
    cmd: PayloadCommandsEnum.CameraFocalLengthSet,
    data: PostCameraFocalLengthBody
} | {
    cmd: PayloadCommandsEnum.GimbalReset,
    data: PostGimbalResetBody
} | {
    cmd: PayloadCommandsEnum.CameraAim,
    data: PostCameraAimBody
}

//endregion data and class payload



//region data and class drone

export const WaylineLostControlActionInCommandFlight = Object.freeze({
    "CONTINUE" : 0,
    "EXEC_LOST_ACTION" : 1
});

export const LostControlActionInCommandFLight = Object.freeze({
    "HOVER" : 0, // 호버링
    "Land" : 1, // 착륙
    "RETURN_HOME" : 2, // RTL
});
export const ERthMode  = Object.freeze({
    "SMART" : 0,
    "SETTING" : 1
});

export const ECommanderModeLostAction  = Object.freeze({
    "CONTINUE" : 0,
    "EXEC_LOST_ACTION" : 1
});

export const ECommanderFlightMode  = Object.freeze({
    "SMART" : 0,
    "SETTING" : 1
});

export class PointBody {
    constructor(_latitude, _longitude, _height) {
        this.latitude = _latitude
        this.longitude = _longitude
        this.height = _height
    }
}
export class PostFlyToPointBody {
    constructor(_max_speed, _points) {
        this.max_speed = _max_speed
        //array is PointBody
        this.points = _points ? [] : _points
    }
}


export class PostTakeoffToPointBody {
    constructor(_target_height, _target_latitude, _target_longitude, _security_takeoff_height, _max_speed, _rc_lost_action, _rth_altitude, _exit_wayline_when_rc_lost, _rth_mode, _commander_mode_lost_action, _commander_flight_mode, _commander_flight_height) {
        this.target_height = _target_height
        this.target_latitude = _target_latitude
        this.target_longitude = _target_longitude
        this.security_takeoff_height = _security_takeoff_height
        this.max_speed = _max_speed
        //rc_lost_action is LostControlActionInCommandFLight
        this.rc_lost_action = _rc_lost_action
        this.rth_altitude = _rth_altitude
        //exit_wayline_when_rc_lost is WaylineLostControlActionInCommandFlight
        this.exit_wayline_when_rc_lost = _exit_wayline_when_rc_lost
        this.rth_mode = _rth_mode

        //commander_mode_lost_action is ECommanderModeLostAction
        this.commander_mode_lost_action = _commander_mode_lost_action
        //commander_flight_mode is ECommanderFlightMode
        this.commander_flight_mode = _commander_flight_mode
        this.commander_flight_height = _commander_flight_height
    }
}

//endregion data and class drone
